
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        


































































































































































































/* stylelint-disable declaration-no-important, max-nesting-depth, no-descending-specificity */
.pack-choice,
[class*='pack-choice--'] {
  // position: absolute;
  // right: 0;
  overflow: hidden;
  width: 100vw;
  max-width: 111rem;
  min-height: 100%;
  padding: 5rem 2rem;
  background: $white;

  ::v-deep {
    .hero-card__nav,
    .promo-card__nav {
      display: none;
    }

    .promo-card {
      max-height: 45rem;
      box-shadow: 0 20px 40px rgba($black, 0.2);
    }

    .promo-card__title {
      width: 100%;
    }

    .promo-card__picture-outer {
      margin: $spacing auto 0;

      // &::after {
      //   width: 25rem;
      //   height: 25rem;
      //   transform: translate(-50%, -50%);
      // }
    }

    .flickity-viewport {
      transition: height 0.2s;
    }
  }

  @include mq(m) {
    padding: 5rem;

    // ::v-deep {
    //   .promo-card__picture-outer {
    //     &::after {
    //       transform: translate(-50%, 0);
    //     }
    //   }
    // }
  }
}

.pack-choice__inner {
  // overflow: hidden;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s;

  .is-ready & {
    visibility: visible;
    opacity: 1;
  }

  @include mq(l) {
    display: flex;
    align-items: flex-start;
  }
}

.pack-choice__header {
  @extend %fw-medium;

  margin-bottom: 1rem;
  font-family: $ff-alt;
  font-size: 1.8rem;
  line-height: 1;
  text-align: center;

  ::v-deep strong {
    display: block;
    font-size: 3.6rem;
  }

  @include mq(l) {
    max-width: 52rem;
    margin-bottom: 4rem;
    font-size: 2.4rem;

    ::v-deep strong {
      font-size: 4.8rem;
    }
  }
}

.pack-choice__slider {
  padding-top: 2rem;
  padding-bottom: 2rem;

  ::v-deep {
    .promo-label {
      pointer-events: none;
    }

    .generic-slider__slides {
      position: relative;

      @include mq($until: l) {
        text-align: center;
      }
    }

    .promo-label__arrow {
      display: none;
    }

    .flickity-button.next,
    .flickity-button.previous {
      display: inline-flex;
      margin: $spacing * 2 0;

      @include mq(l) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .flickity-button.previous {
      margin-right: 1rem;

      @include mq(l) {
        left: 0;
        margin-right: 0;
      }
    }

    .flickity-button.next {
      @include mq(l) {
        right: 0;
      }
    }
  }

  @include mq(l) {
    display: flex;
    flex-shrink: 0;
    overflow: hidden;
    max-width: 50rem;
    margin-right: 2rem;
  }
}

.pack-choice__slider__item {
  margin-right: 7rem;

  ::v-deep .product-card__inner {
    box-shadow: 0 6px 10px rgb(0 0 0 / 30%);
  }
}

.pack-choice__advantage {
  flex: 1;

  @include mq(l) {
    padding-left: 2rem;
    // border-left: 1px solid $c-gray-lightest;
  }
}

.pack-choice__advantage__header {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @include mq(m) {
    flex-direction: column;
    justify-content: center;
  }
}

.pack-choice__advantage__header__plus {
  @extend %fw-bold;

  margin-right: 1rem;
  color: $c-pink-medium;
  font-family: $ff-alt;
  font-size: 3.6rem;
  line-height: 1;

  @include mq(m) {
    font-size: 5.8rem;
  }
}

.pack-choice__advantage__header__title {
  @extend %fw-bold;

  font-family: $ff-alt;
  font-size: 2.4rem;

  @include mq(m) {
    font-size: 3.2rem;
  }
}

.pack-choice__advantage__card {
  width: auto !important;
  margin: 3rem 3rem 5rem;

  ::v-deep .promo-card__content {
    flex-grow: 1;
  }

  @include mq($until: s) {
    ::v-deep .promo-card__speedlines-outer {
      width: 100%;
    }
  }

  @include mq(m) {
    margin-top: $spacing * 3;
  }
}
